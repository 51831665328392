import React, { useRef, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import icon from '../../../assets/images/icon-minus.svg';

import styles from './Accordion.module.scss';

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  const contentHeight = useRef();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (contentHeight.current) {
      setHeight(isOpen ? contentHeight.current.scrollHeight : 0);
    }
  }, [isOpen]);
  
  return (
    <div className={`${styles.item} ${isOpen ? `${styles.itemActive}` : ""}`}>
      <button
        className={styles.title}
        onClick={onClick}
      >
        <p>{title}</p>
        <img src={icon} alt="icon" className={styles.icon} />
      </button>

      <div
        ref={contentHeight}
        className={styles.content}
        style={
          isOpen
            ? { height: height }
            : { height: "0px" }
        }
      >
        <Trans>{content}</Trans>
      </div>
    </div>
  );
};

export default AccordionItem;