import React from "react";
import styles from './TextGrid.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Button from '../../GlodalComponents/Button/Button';

import image1 from '../../../assets/images/acctypespage/image1.webp';
import image2 from '../../../assets/images/acctypespage/image2.webp';
import image3 from '../../../assets/images/acctypespage/image3.webp';

const TextGrid = () => {
  const { t } = useTranslation();
  const images = [image1, image2, image3];
  const list = t('acc.five.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.first} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-40-25`}><Trans>{t('acc.five.title')}</Trans></div>
          <div className={`${styles.subtitle} font-16-14`}>{t('acc.five.subtitle0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('acc.five.text0')}</div>
          <div className="mt-btn"><Button yellow /></div>
        </div>
        <div className={styles.item}>
          <div className={`${styles.subtitle} font-16-14`}>{t('acc.five.subtitle1')}</div>
          <div className={`${styles.text} font-16-14`}>{t('acc.five.text1')}</div>
        </div>
      </div>
      <div className={`${styles.container}`}>
        {list.map((item, index) => (
          <div className={styles.row} key={index}>
            <Fade left={index % 2 !== 0} right={index % 2 === 0} duration={500} delay={index * 1000 + 100} key={index}>
              <div className={`${styles.rowItemImage} ${styles.rowItem}`}>
                <img src={images[index]} alt="" />
              </div>
            </Fade>

            <div className={`${styles.rowItemContent}  ${styles.rowItem}`}>
              <div className={styles.rowItemTitle}>{item.title}</div>
              <div className={styles.rowItemText}><Trans>{item.text}</Trans></div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TextGrid;