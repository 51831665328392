import React from "react";
import styles from './ImageGrid.module.scss';
import { Trans, useTranslation } from "react-i18next";

import image1 from '../../../assets/images/tradingpage/image2.webp';
import image2 from '../../../assets/images/tradingpage/image3.webp';
import image3 from '../../../assets/images/tradingpage/image4.webp';
import image4 from '../../../assets/images/tradingpage/image5.webp';

const ImageGrid = () => {
  const { t } = useTranslation();
  const list = t('trading.third.list', { returnObjects: true });
  const images = [image1, image2, image3, image4];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        {list.map((item, index) => (
          <div className={styles.item} key={index}>
            <img src={images[index]} alt="" className={styles.image} />
            <span className={styles.sign}><Trans>{t(`${item.title}`)}</Trans></span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ImageGrid;