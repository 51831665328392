import React from "react";
import styles from './List.module.scss';
import { useTranslation } from "react-i18next";

import icon1 from '../../../assets/images/bonuspage/hover1.webp';
import icon2 from '../../../assets/images/bonuspage/hover2.webp';
import icon3 from '../../../assets/images/bonuspage/hover3.webp';
import arrow from '../../../assets/images/arr-right.svg';

const List = () => {
  const { t } = useTranslation();
  const list = t('bonus.sec.list', { returnObjects: true });
  const icons = [icon1, icon2, icon3];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        {list.map((item, index) => (
          <div className={styles.item} key={index}>
            <img src={icons[index]} alt="" className={`${styles.itemImage} ${styles[`image-${index}`]}`} />
            <div className={styles.itemBlockTitle}>
              <div className={`${styles.itemSubtitle} font-30-25`}>{item.title}</div>
              <div className={`${styles.itemTitle} font-16-14`}>{item.subtitle}</div>
            </div>
            <div className={styles.itemBlock}>
              <div className={`${styles.itemText} font-16-14`}>{item.text}</div>
              <img src={arrow} alt="" className={styles.itemIcon} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default List;