import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccordionItem from './AccordionItem';
import styles from './Accordion.module.scss';

const Accordion = ({ title }) => {
  const { t } = useTranslation();
  const items = t(`${title}`, { returnObjects: true });
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={styles.accordion}>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.text}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;