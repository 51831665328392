import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image1 from '../../../assets/images/supportpage/image1.webp';
import image2 from '../../../assets/images/supportpage/image2.webp';

const Banner = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <div className={styles.item}>
            <Fade left><h1 className={`${styles.title} font-75-50`}><Trans>{t('support.first.title')}</Trans></h1></Fade>
            <Fade left><h3 className={`${styles.subtitle} font-40-25`}><Trans>{t('support.first.subtitle')}</Trans></h3></Fade>
          </div>
          <div className={`${styles.item} ${styles.blockText}`}>
            <Fade left><div className={`${styles.text} font-16-14`}><Trans>{t('support.first.text0')}</Trans></div></Fade>
            <Fade left><div className={`${styles.text} font-16-14`}><Trans>{t('support.first.text1')}</Trans></div></Fade>
          </div>
        </div>
        <div className={`${styles.row} ${styles.mart}`}>
          <div className={styles.item}>
            <img src={image1} alt="" />
            <div className={styles.itemAbs}>
              <div className={`${styles.itemText} font-20-16`}>{t('support.first.block1.title')}</div>
              <div className={`${styles.itemText} font-20-16`}>{t('support.first.block1.text')}</div>
            </div>
          </div>
          <div className={styles.item}>
            <img src={image2} alt="" />
            <div className={styles.itemAbs}>
              <div className={`${styles.itemText} font-20-16`}>{t('support.first.block2.title')}</div>
              <div className={`${styles.itemText} font-20-16`}>{t('support.first.block2.text')} <a href={`mailto:support@${hostname}`}>
              support@{hostname}
            </a></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;