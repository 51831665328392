import React from "react";
import styles from './Earn.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import icon1 from '../../../assets/images/homepage/icon4.svg';
import icon2 from '../../../assets/images/homepage/icon5.svg';
import icon3 from '../../../assets/images/homepage/icon6.svg';

const TradeMore = () => {
  const { t } = useTranslation();
  const list = t('bonus.third.list', { returnObjects: true });
  const icons = [icon1, icon2, icon3];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-40-25`}>{t('bonus.third.title')}</div>
        <div className={styles.list}>
          {list.map((item, index) => (
            <Fade bottom delay={index * 100 + 1} key={index}>
              <div className={styles.item}>
                <img src={icons[index]} alt="" className={styles.itemIcon} />
                <div className={styles.itemTitle}>{item.title}</div>
                <div className={styles.itemText}>{item.text}</div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TradeMore;