import React from "react";
import styles from './UseVarious.module.scss';
import { Trans, useTranslation } from "react-i18next";

const UseVarious = () => {
  const { t } = useTranslation();
  const list = t('trading.five.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-40-25`}>{t('trading.five.title')}</div>
        <div className={styles.list}>
          {list.map((item, index) => (
            <div className={styles.listItem} key={index}>
              <div className={`${styles.listItemTitle} font-16-14`}><Trans>{t(`${item.title}`)}</Trans></div>
              <div className={`${styles.listItemText} font-16-14`}><Trans>{t(`${item.text}`)}</Trans></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseVarious;