import React from "react";
import styles from './Various.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Button from "../../GlodalComponents/Button/Button";

import image1 from '../../../assets/images/homepage/image2.webp';
import image2 from '../../../assets/images/homepage/image3.webp';
import image3 from '../../../assets/images/homepage/image4.webp';
import image4 from '../../../assets/images/homepage/image5.webp';

const Various = () => {
  const { t } = useTranslation();
  const list = t('main.third.list', { returnObjects: true });
  const images = [image1, image2, image3, image4];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <div className={`${styles.title} font-75-25`}><Trans>{t('main.third.title0')}</Trans></div>
          </div>
          <div className={`${styles.text} ${styles.rowItem} font-16-14`}><Trans>{t('main.third.text')}</Trans></div>
        </div>
          <div className={styles.list}>
            {list.map((item, index) => (
              <Fade left={index % 2 === 0} right={index % 2 !== 0} duration={1000} delay={index * 100 + 100} key={index}>
                <div className={styles.listItem}>
                  <div className={styles.textBlock}>
                    <div className={`${styles.listItemTitle} font-40-25`}>
                      <Trans>{t(`${item.title}`)}</Trans>
                    </div>
                    <div className={`${styles.listItemText} font-20-14`}>
                      <Trans>{t(`${item.text}`)}</Trans>
                    </div>
                    <div className="mt-btn"><Button yellow /></div>
                  </div>
                  <div className={styles.imgWrap}>
                    <img src={images[index]} alt="" />
                  </div>
                </div>
              </Fade>
            ))}
          </div>
      </div>
    </section>
  );
};

export default Various;