import React from "react";
import styles from './Steps.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../Button/Button";

import image from '../../../assets/images/homepage/image9.webp';
import imagemob from '../../../assets/images/homepage/image9-mob.webp';

const Steps = () => {
  const { t } = useTranslation();
  const list = t('main.eight.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <div className={`${styles.title} font-50-25`}><Trans>{t('main.eight.title0')}</Trans></div>
            <div className={`${styles.subtitle} font-40-25`}><Trans>{t('main.eight.title1')}</Trans></div>
            <div className="mt-btn"><Button yellow /></div>
          </div>

          <div className={styles.imgWrap}>
            <img src={image} alt="" className={`${styles.image} desk_only`} />
            <img src={imagemob} alt="" className={`${styles.image} mob_only`} />
          </div>
        </div>
        <div className={styles.list}>
          {list.map((item, index) => (
            <div className={styles.listItem} key={index}>
              <div className={`${styles.listItemIndex} font-40-25`}>0{index + 1}</div>
              <div className={`${styles.listItemTitle} font-20-14`}>{item.title}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Steps;