import React from "react";
import styles from './Excition.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Button from "../../GlodalComponents/Button/Button";

import icon from '../../../assets/images/icon-check.svg';

const Excition = () => {
  const { t } = useTranslation();
  const list = t('main.five.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.left}>
          <div className={`${styles.title} font-40-25`}>{t('main.seven.title')}</div>
          <div className={`${styles.text} font-16-14`}>{t('main.seven.text')}</div>
          <div className="mt-btn"><Button yellow /></div>
        </div>
        <div className={styles.list}>
          {list.map((item, index) => (
            <Fade bottom delay={index * 100 + 1} key={index}>
              <div className={styles.listItem}>
                <img src={icon} alt="" className={styles.itemIcon} />
                <div className={styles.itemText}>{item.title}</div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Excition;