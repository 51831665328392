import React from "react";
import styles from './ThreeRow.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import icon1 from '../../../assets/images/homepage/icon1.svg';
import icon2 from '../../../assets/images/homepage/icon2.svg';
import icon3 from '../../../assets/images/homepage/icon3.svg';

const ThreeRow = () => {
  const { t } = useTranslation();
  const list = t('main.sec.list', { returnObjects: true });
  const icons = [icon1, icon2, icon3];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        {list.map((item, index) => (
          <Fade bottom delay={index * 100 + 1} key={index}>
            <div className={styles.item}>
              <img src={icons[index]} alt="" className={styles.itemIcon} />
              <div className={styles.itemText}>{item.title}</div>
            </div>
          </Fade>
        ))}
      </div>
    </section>
  );
};

export default ThreeRow;