import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/SupportPage/Banner/Banner';
import AccWrap from '../components/SupportPage/AccWrap/AccWrap';
import Tabs from '../components/SupportPage/Tabs/Tabs';

function ProductsPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Esmoris · Support and FAQ</title>
        <meta name="title" content="Your Triumph, Our Priority - Exceptional Support and Innovative Solutions at Esmoris" />
        <meta property="og:title" content="Your Triumph, Our Priority - Exceptional Support and Innovative Solutions at Esmoris" />
        <meta property="twitter:title" content="Your Triumph, Our Priority - Exceptional Support and Innovative Solutions at Esmoris" />

        <meta name="description" content="At Esmoris, your trading success is our top priority. Our extensive bonus system is designed to enhance your experience from the start. Enjoy a substantial sign-up bonus, boost your trading resources with deposit bonuses, and earn rewards correlated with your trading volume." />
        <meta property="og:description" content="At Esmoris, your trading success is our top priority. Our extensive bonus system is designed to enhance your experience from the start. Enjoy a substantial sign-up bonus, boost your trading resources with deposit bonuses, and earn rewards correlated with your trading volume." />
        <meta property="twitter:description" content="At Esmoris, your trading success is our top priority. Our extensive bonus system is designed to enhance your experience from the start. Enjoy a substantial sign-up bonus, boost your trading resources with deposit bonuses, and earn rewards correlated with your trading volume." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='support-page ovf-hidden'>
        <Header />
        <Banner />
        <AccWrap />
        <Tabs />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default ProductsPage;
