import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import ThreeRow from '../components/HomePage/ThreeRow/ThreeRow';
import Various from '../components/HomePage/Various/Various';
import UseVarious from '../components/HomePage/UseVarious/UseVarious';
import TradeMore from '../components/HomePage/TradeMore/TradeMore';
import TableTabs from '../components/GlodalComponents/TableTabs/TableTabs';
import Excition from '../components/HomePage/Excition/Excition';

function HomePage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Esmoris</title>
        <meta name="title" content="Esmoris: Harness the Potential of Cryptocurrency Trading!" />
        <meta property="og:title" content="Esmoris: Harness the Potential of Cryptocurrency Trading!" />
        <meta property="twitter:title" content="Esmoris: Harness the Potential of Cryptocurrency Trading!" />

        <meta name="description" content="Step into the realm of Esmoris, your gateway to the dynamic and exciting world of cryptocurrency trading." />
        <meta property="og:description" content="Step into the realm of Esmoris, your gateway to the dynamic and exciting world of cryptocurrency trading." />
        <meta property="twitter:description" content="Step into the realm of Esmoris, your gateway to the dynamic and exciting world of cryptocurrency trading." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden'>
        <Header />
        <Banner />
        <ThreeRow />
        <Various />
        <UseVarious />
        <TradeMore />
        <TableTabs title='main.six.title' tabs='main.six.list' />
        <Excition />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;
