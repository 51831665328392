import React from "react";
import styles from './AccWrap.module.scss';
import Accordion from '../../../components/GlodalComponents/Accordion/Accordion';
import { useTranslation } from "react-i18next";

const AccWrap = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-40-25`}>{t('support.sec.title')}</div>
        <Accordion title='support.sec.list' />
      </div>
    </section>
  );
};

export default AccWrap;