import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/TradingPage/Banner/Banner';
import List from '../components/TradingPage/List/List';
import ImageGrid from '../components/TradingPage/ImageGrid/ImageGrid';
import EvoCrypto from '../components/TradingPage/EvoCrypto/EvoCrypto';
import UseVarious from '../components/TradingPage/UseVarious/UseVarious';
import FairPlay from '../components/TradingPage/FairPlay/FairPlay';

function ServicesPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Esmoris · Trading assets</title>
        <meta name="title" content="Explore a World of Diverse Trading Opportunities at Esmoris!" />
        <meta property="og:title" content="Explore a World of Diverse Trading Opportunities at Esmoris!" />
        <meta property="twitter:title" content="Explore a World of Diverse Trading Opportunities at Esmoris!" />

        <meta name="description" content="Esmoris offers an extensive selection of trading assets and instruments, tailored to accommodate various trading styles and strategies." />
        <meta property="og:description" content="Esmoris offers an extensive selection of trading assets and instruments, tailored to accommodate various trading styles and strategies." />
        <meta property="twitter:description" content="Esmoris offers an extensive selection of trading assets and instruments, tailored to accommodate various trading styles and strategies." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='trading-page ovf-hidden'>
        <Header />
        <Banner />
        <List />
        <ImageGrid />
        <EvoCrypto />
        <UseVarious />
        <FairPlay />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default ServicesPage;
