import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import image from '../../../assets/images/bonuspage/image1.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <Fade left><h1 className={`${styles.title} font-75-50`}><Trans>{t('bonus.first.title')}</Trans></h1></Fade>
        <Fade left><h3 className={`${styles.subtitle} font-40-25`}><Trans>{t('bonus.first.subtitle')}</Trans></h3></Fade>
        <Fade left><div className={`${styles.text} font-16-14`}><Trans>{t('bonus.first.text')}</Trans></div></Fade>
        <Fade left><div className="mt-btn"><Button yellow /></div></Fade>
        <div className={`${styles.abs}`}>
          <Fade bottom delay={200}><img src={image} alt="" className={styles.bg} /></Fade>
        </div>
      </div>
    </section>
  );
};

export default Banner;