import React from "react";
import styles from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import image from '../../../assets/images/homepage/image1.webp';
import imagemob from '../../../assets/images/homepage/image-mob.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <Fade right><h1 className={`${styles.title} font-75-25`}><Trans>{t('main.first.title0')}</Trans></h1></Fade>
        <Fade right><h3 className={`${styles.subtitle} font-75-25`}><Trans>{t('main.first.title1')}</Trans></h3></Fade>
        <div className={styles.row}>
          <div className={styles.left}>
            <Fade left delay={50}><h5 className={`${styles.text} font-33 desk_only`}><Trans>{t('main.first.text0')}</Trans></h5></Fade>
            <Fade left delay={50}><h5 className={`${styles.text} font-16-14`}><Trans>{t('main.first.text1')}</Trans></h5></Fade>
            <Fade right delay={100}>
              <div className='mt-btn'>
                <Button yellow />
              </div>
            </Fade>
          </div>
          <div className={styles.right}>
            <Fade right delay={250}><h5 className={`${styles.text} font-33 desk_only`}><Trans>{t('main.first.text2')}</Trans></h5></Fade>
            <Fade right delay={250}><h5 className={`${styles.text} font-16-14`}><Trans>{t('main.first.text3')}</Trans></h5></Fade>
          </div>
        </div>
        <div className={styles.abs}>
          <Fade bottom delay={200}><img src={image} alt="" className={`${styles.bg} desk_only`} /></Fade>
          <img src={imagemob} alt="" className={`${styles.bg} mob_only`} />
        </div>
      </div>
    </section>
  );
};

export default Banner;