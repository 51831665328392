import React from "react";
import styles from './UseVarious.module.scss';
import { Trans, useTranslation } from "react-i18next";

import image1 from '../../../assets/images/homepage/image6.webp';
import image2 from '../../../assets/images/homepage/image7.webp';
import image3 from '../../../assets/images/homepage/image8.webp';

const UseVarious = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.row}>
          <div className={`${styles.rowItem} ${styles.rowItemFirst}`}>
            <div className={`${styles.title} font-75-25`}><Trans>{t('main.four.title0')}</Trans></div>
            <div className={`${styles.text} font-16-14`}><Trans>{t('main.four.text0')}</Trans></div>
          </div>
          <div className={`${styles.rowItem} ${styles.imageFirst}`}>
            <img src={image1} alt="" className={styles.imgWrap} />
            <span className={styles.sign}>{t('main.four.text1')}</span>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.rowItem}>
            <img src={image2} alt="" className={`${styles.imageSec}  ${styles.imgWrap}`} />
            <span className={styles.sign}>{t('main.four.text2')}</span>
          </div>
          <div className={styles.rowItem}>
            <img src={image3} alt="" className={`${styles.imageSec}  ${styles.imgWrap}`} />
            <span className={styles.sign}>{t('main.four.text3')}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseVarious;