import React from "react";
import styles from './FairPlay.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

import image from '../../../assets/images/tradingpage/image7.webp';

const FairPlay = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
          <div className={styles.item}>
            <Fade left delay={50}><div className={`${styles.title} font-40-25`}><Trans>{t('trading.six.title')}</Trans></div></Fade>
            <Fade left delay={50}><div className={`${styles.text} font-16-14`}><Trans>{t('trading.six.text')}</Trans></div></Fade>
            <Fade left delay={100}>
              <div className='mt-btn'>
                <Button yellow />
              </div>
            </Fade>
          </div>
          <div className={styles.item}>
            <img src={image} alt="" />
          </div>
      </div>
    </section>
  );
};

export default FairPlay;