import React from "react";
import styles from './Awards.module.scss';
import { useTranslation } from "react-i18next";

import icon1 from '../../../assets/images/aboutpage/awards1.webp';
import icon2 from '../../../assets/images/aboutpage/awards2.webp';
import icon3 from '../../../assets/images/aboutpage/awards3.webp';
import icon4 from '../../../assets/images/aboutpage/awards4.webp';

const Awards = () => {
  const { t } = useTranslation();
  const icons = [icon1, icon2, icon3, icon4];

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-40-25`}>{t('about.sec.title')}</div>
        <div className={`${styles.text} font-16-14`}>{t('about.sec.text')}</div>
        <div className={styles.list}>
          {icons.map((item, index) => (
            <div className={styles.item} key={index}>
              <img src={item} alt=""  />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Awards;