import HomePage from '../pages/HomePage';
import TradingPage from '../pages/TradingPage';
import AccountsPage from '../pages/AccountsPage';
import BonusPage from '../pages/BonusPage';
import SupportPage from '../pages/SupportPage';
import AboutPage from '../pages/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage},
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/bonus', element: BonusPage },
    { path: '/:lang/support', element: SupportPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { path: 'trading', text: 'header.trading' },
    { path: 'accounts', text: 'header.accounts' },
    { path: 'bonus', text: 'header.bonus' },
    { path: 'support', text: 'header.support' },
    { path: 'about', text: 'header.about' },
];