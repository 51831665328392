import React from "react";
import styles from './Terms.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import image from '../../../assets/images/bonuspage/image2.webp';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
          <div className={styles.item}>
            <div className={`${styles.title} font-40-25`}><Trans>{t('bonus.sec.title')}</Trans></div>
            <div className={`${styles.text} font-16-14`}><Trans>{t('bonus.sec.text0')}</Trans></div>
            <div className={`${styles.text} font-16-14`}><Trans>{t('bonus.sec.text1')}</Trans></div>
            <div className='mt-btn'>
              <Button yellow>{t('btn.learn')}</Button>
            </div>
          </div>
          <div className={styles.item}>
            <img src={image} alt="" />
          </div>
      </div>
    </section>
  );
};

export default Terms;